import React from 'react';
import { Route } from 'react-router-dom';
import { ROUTES } from '../routes';
import { JobRoutes } from './JobRoutes';

// Lazy load the pages
const AllClients = React.lazy(() =>
  import('../../pages/Connection/AllClients').then((module) => ({ default: module.AllClients })),
);
const Client = React.lazy(() => import('../../pages/Connection/Client').then((module) => ({ default: module.Client })));

const Jobs = React.lazy(() => import('../../pages/Connection/Jobs').then((module) => ({ default: module.Jobs })));
const Upload = React.lazy(() => import('../../pages/Connection/Upload').then((module) => ({ default: module.Upload })));
const FileStorage = React.lazy(() =>
  import('../../pages/Connection/FileStorage').then((module) => ({ default: module.FileStorage })),
);
const AttributeMapping = React.lazy(() =>
  import('../../pages/Connection/AttributeMapping').then((module) => ({ default: module.AttributeMapping })),
);

export const ConnectRoutes = () => {
  return (
    <>
      <Route exact path={`${ROUTES.CONNECT.path}/:id/attribute-mapping`}>
        <AttributeMapping />
      </Route>
      <Route exact path={`${ROUTES.CONNECT.path}/:id/upload`}>
        <Upload />
      </Route>
      <Route exact path={`${ROUTES.CONNECT.path}/:id/file-storage`}>
        <FileStorage />
      </Route>

      <Route path={`${ROUTES.CONNECT.path}/:id/jobs/:jobId`}>
        <JobRoutes />
      </Route>
      <Route exact path={`${ROUTES.CONNECT.path}/:id/jobs`}>
        <Jobs />
      </Route>

      <Route exact path={`${ROUTES.CONNECT.path}/:id`}>
        <Client />
      </Route>
      <Route exact path={ROUTES.CONNECT.path}>
        <AllClients />
      </Route>
    </>
  );
};
