import React from 'react';
import { Route } from 'react-router-dom';
import { MatchingProvider, LookbackProvider } from '@acin/data-matching';
import { ROUTES } from '../routes';

// Lazy load the pages
const Job = React.lazy(() => import('../../pages/Connection/Job').then((module) => ({ default: module.Job })));
const Controls = React.lazy(() =>
  import('../../pages/Connection/Controls').then((module) => ({ default: module.Controls })),
);
const Risks = React.lazy(() => import('../../pages/Connection/Risks').then((module) => ({ default: module.Risks })));
const MatchingScreen = React.lazy(() =>
  import('../../pages/Connection/MatchingScreen').then((module) => ({ default: module.MatchingScreen })),
);

const LookbackScreen = React.lazy(() =>
  import('../../pages/Connection/LookbackScreen').then((module) => ({ default: module.LookbackScreen })),
);

export const JobRoutes = () => {
  return (
    <>
      <MatchingProvider>
        <Route exact path={`${ROUTES.CONNECT.path}/:id/jobs/:jobId/controls/matching-screen`}>
          <MatchingScreen />
        </Route>

        <LookbackProvider>
          <Route exact path={`${ROUTES.CONNECT.path}/:id/jobs/:jobId/controls/lookback-screen`}>
            <LookbackScreen />
          </Route>
          <Route exact path={`${ROUTES.CONNECT.path}/:id/jobs/:jobId/controls`}>
            <Controls />
          </Route>
        </LookbackProvider>
      </MatchingProvider>
      <Route exact path={`${ROUTES.CONNECT.path}/:id/jobs/:jobId/risks`}>
        <Risks />
      </Route>
      <Route exact path={`${ROUTES.CONNECT.path}/:id/jobs/:jobId`}>
        <Job />
      </Route>
    </>
  );
};
